@tailwind base;
@tailwind components;
@tailwind utilities;

.mui-button {
  background-color: #2196f3;
  color: white;
}

.mui-error {
  background-color: #f44336;
}
